<template>
  <el-card class="box-card">
    <el-table
        :data="list"
        style="width: 100%">
      <el-table-column
          align="center"
          prop="name"
          label="商品名称">
      </el-table-column>
      <el-table-column
          align="center"
          prop="user_id"
          label="售价">
      </el-table-column>
      <el-table-column
          align="center"
          label="浏览时间"
          prop="browse_at"
          show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div>{{ scope.row.create_at | formatDate }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :current-page="queryForm.page"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
  </el-card>
</template>
<script>
import { formatDate } from '@/utils/theTimeStamp'
export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    },
  },
  components: {},
  props: [],
  data() {
    return {
      imgShow: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
      },
      list: [
        {
          id: '1',
          name: '益力多',
          user_id: '1',
          create_at: '1647584613',
        },
        {
          id: '1',
          name: '益力多',
          user_id: '1',
          create_at: '1647584613',
        },
        {
          id: '1',
          name: '益力多',
          user_id: '1',
          create_at: '1647584613',
        },
      ],
    }
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      const { data } = await getList(this.queryForm)
      this.list = data.list
      this.count = data.count
      this.count = parseInt(data.count)
      if (!this.list.length) this.queryForm.page++
      this.listLoading = false
    },
  }
}
</script>
<style>
  .el-pagination {
    text-align: center;
    margin-top: 20px;
  }

  .el-form-item {
    margin-bottom: 0;
  }

  .el-dropdown {
    margin-right: 10px;
  }

  .page {
    background-color: #FFF;
    padding: 20px;
    margin: 20px;
  }

  .el-row {
    margin-bottom: 15px;
  }
</style>
